import dayjs from 'dayjs';
import { localeObject } from '@/utils/localeObjects.ts';

export const timeStays = [
  { text: 'Weekend', id: 0, value: ['W'] },
  { text: '1 night', id: 1, value: [1] },
  { text: '2-3 nights', id: 2, value: [2, 3] },
  { text: '4-6 nights', id: 3, value: [4, 6] },
  { text: '7-10 nights', id: 4, value: [7, 10] },
  { text: '11-14 nights', id: 5, value: [11, 14] },
];

export const getDates = (language: string) => {
  return Array.from({ length: 11 }).map((_, index) => ({
    month: dayjs().add(index, 'month').locale(language, localeObject[language]),
    id: index + 1,
    days: {
      init: dayjs().add(index, 'month').startOf('month'),
      end: dayjs().add(index, 'month').endOf('month'),
    },
  }));
};
