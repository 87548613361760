import { useGetFlag } from '@/hooks/useGetFlag';
import { useStore } from '@/store/useStore';
import { TravelSearch } from '@/views/TravelSearch';
import loadable from '@loadable/component';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ErrorScreen } from '../ErrorScreen/ErrorScreen';

dayjs.extend(utc);
dayjs.extend(timezone);

const HotelsFlightsSearch = loadable(
  () => import('@/views/HotelsFlightsSearch'),
  {
    resolveComponent: (component) => component.HotelsFlightsSearch,
  }
);

const Flights = loadable(() => import('@/views/Flights'), {
  resolveComponent: (component) => component.Flights,
});

const Checkout = loadable(() => import('@/views/Checkout/Checkout'), {
  resolveComponent: (component) => component.Checkout,
});

const ThankYou = loadable(() => import('@/views/Checkout/ThankYou'), {
  resolveComponent: (component) => component.ThankYou,
});

const HotelDetail = loadable(() => import('@/views/HotelDetail'), {
  resolveComponent: (component) => component.HotelDetail,
});

const MemSearch = loadable(() => import('@/views/MemSearch'), {
  resolveComponent: (component) => component.MemSearch,
});

const Exception = loadable(() => import('@/views/Exception/Exception'), {
  resolveComponent: (component) => component.Exception,
});

const ExpiredSession = loadable(
  () => import('@/views/ExpiredSession/ExpiredSession'),
  {
    resolveComponent: (component) => component.ExpiredSession,
  }
);

export function Router() {
  const maintenanceMode = useGetFlag('travel.general.maintenance');
  const { search } = useLocation();
  const brandConfig = useStore((state) => state.brandConfig);

  const { t } = useTranslate();

  if (maintenanceMode) {
    return (
      <ErrorScreen
        title={t('maintenance.title')}
        subtitle={t('maintenance.description')}
        ctaLabel={t('maintenance.cta')}
      />
    );
  }

  if (!brandConfig) {
    return <ErrorScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<TravelSearch />} />
      <Route path="/search" element={<Navigate to={`/${search}`} replace />} />
      <Route path="/DP" element={<HotelsFlightsSearch />} />
      <Route path="/H" element={<HotelsFlightsSearch />} />
      <Route path="/F" element={<Flights />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/hotel-detail" element={<HotelDetail />} />
      <Route path="/memSearch" element={<MemSearch />} />
      <Route path="/exception" element={<Exception />} />
      <Route path="/expired-session" element={<ExpiredSession />} />
    </Routes>
  );
}
