import { SearchItem } from '@/services/codegen-romulo';
import { ResultElementProps } from '../components/SearchInput/ResultElement';
import { getLocationIcon } from './getLocationIcon';
import { getTitle } from './getTitle';

export const fromSearchItemToResultElement = ({
  item,
  isSubItem = false,
  selected = false,
  disabled = false,
}: {
  item: SearchItem;
  isSubItem?: boolean;
  selected?: boolean;
  disabled?: boolean;
}): ResultElementProps => {
  return {
    title: getTitle(item),
    icon: getLocationIcon(item.type),
    subtitle: item.additionalInfo,
    type: item.type,
    code: item.code,
    name: item.name,
    id: item.id,
    additionalInfo: item.additionalInfo,
    location: item.location,
    selected,
    disabled,
    isSubElement: isSubItem,
  };
};
