import { Dayjs } from 'dayjs';
import * as React from 'react';
import { getDates } from './FlexiDatesSelector/utils';

type FlexiDatesDataProps = {
  dateToLabel?: string;
  dayInit?: string;
  isAnyDate: boolean;
  dateFrom?: string;
  dateTo: string;
  monthInit: Dayjs | null;
  monthEnd: Dayjs | null;
};

export function useFlexiDates(brandConfig) {
  const [flexiDatesData, setFlexiDatesData] =
    React.useState<FlexiDatesDataProps>({
      dateToLabel: 'Weekend',
      dayInit: 'any time',
      isAnyDate: true,
      dateFrom: 'A',
      dateTo: 'W',
      monthInit: null,
      monthEnd: null,
    });

  const dates = getDates(brandConfig.language);

  const onTimeStay = (data) => {
    setFlexiDatesData((s) => ({
      ...s,
      dateTo: `T,${data?.value?.join(',')}`,
      dateToLabel: data.text,
      dateId: data.id,
    }));
  };

  const onDate = (value) => {
    const dayInitFormatted = value?.dayInit?.format('YYYY-MM-DD');

    const dayEndFormatted = value?.dayEnd?.format('YYYY-MM-DD');

    setFlexiDatesData((s) => ({
      ...s,
      ...value,
      dateFrom: value.isAnyDate
        ? 'A'
        : `${dayInitFormatted},${dayEndFormatted}`,
    }));
  };

  const dateTimeline = `${flexiDatesData?.monthInit?.format('MMM')}${flexiDatesData?.monthEnd?.format('MMM') ? ` - ${flexiDatesData?.monthEnd?.format('MMM')}` : ''}`;

  const flexiDatesLabel = `${flexiDatesData?.dateToLabel} • ${flexiDatesData.isAnyDate ? 'any time' : dateTimeline}`;

  return {
    flexiDatesLabel,
    onDate,
    onTimeStay,
    flexiDatesData,
    dates,
  };
}
