import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ButtonArrowLeft = styled.button<{ isVisible: boolean }>`
  align-items: center;
  background: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 0px;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
    `}

  @media (max-width: 756px) {
    display: none;
  }
`;

export const ButtonArrowRight = styled(ButtonArrowLeft)`
  right: 0;
  left: auto;
`;
