import { useIsMobile } from '@/hooks/useIsMobile';
import SkeletonMui, { SkeletonProps } from '@mui/material/Skeleton';
import * as Styled from './Skeleton.styled';

export function SkeletonUI({ sx, ...props }: SkeletonProps) {
  return (
    <SkeletonMui
      animation="wave"
      variant="rectangular"
      sx={{ borderRadius: '4px', bgcolor: 'rgba(0, 0, 0, 0.06)', ...sx }}
      {...props}
    />
  );
}

export function Skeleton() {
  const { isMobile } = useIsMobile();
  return (
    <Styled.Container>
      <Styled.Stack>
        <SkeletonUI
          height={32}
          sx={{
            marginBottom: '16px',
            width: '40%',
          }}
        />
        <SkeletonUI
          height={16}
          sx={{
            maxWidth: isMobile ? '100%' : '400px',
            width: '100%',
            marginBottom: '16px',
          }}
        />
        <SkeletonUI
          height={16}
          sx={{
            maxWidth: isMobile ? '100%' : '400px',
            width: '100%',
          }}
        />
      </Styled.Stack>
    </Styled.Container>
  );
}
