import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import { Divider } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { Popover, theme } from 'antd';
import { Fragment, useState } from 'react';
import * as Styled from './AuthenticationButton.styled';
export function AuthenticationButton() {
  const { token } = theme.useToken(); // TODO Remove antd
  const { t } = useTranslate();
  const currencyFormatter = useCurrencyFormatter();
  const track = useTrack();
  const [externalAuth] = useStore((state) => [state.externalAuth]);

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const handleClick = () => {
    setOpenPopover(!openPopover);
    track({
      eventName: 'clicked.account',
      logLevel: 'info',
    });
  };

  if (!externalAuth?.clientData) return null;

  return (
    <Popover
      content={
        <Styled.PopoverContent>
          {externalAuth ? (
            <Styled.UserInfoContainer>
              <Styled.UserName>
                {externalAuth.clientData.firstName}{' '}
                {externalAuth.clientData.lastName}
              </Styled.UserName>
              <Styled.BrandName>
                {externalAuth.clientData.clientName}
              </Styled.BrandName>
              {externalAuth?.availableBalance && (
                <Fragment>
                  <Divider />
                  <Styled.UserBudget>
                    {t('header.customer.credit')}:{' '}
                    {currencyFormatter.formatNumber(
                      externalAuth.availableBalance
                    )}
                  </Styled.UserBudget>
                </Fragment>
              )}
            </Styled.UserInfoContainer>
          ) : (
            t('header.customer.loading')
          )}
        </Styled.PopoverContent>
      }
      trigger="click"
      open={openPopover}
    >
      <Styled.AuthenticationButton
        onClick={handleClick}
        bgcolor={token.colorFillQuaternary}
      >
        <Styled.UserCircle>
          <Styled.User size={18} />
        </Styled.UserCircle>
        {openPopover ? <Styled.ContractIcon /> : <Styled.ExpandIcon />}
      </Styled.AuthenticationButton>
    </Popover>
  );
}
