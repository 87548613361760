import * as React from 'react';
import { useFlexiDates } from './useFlexiDates';
import { useExactDates } from './CalendarSelector/useExactDates';

export function useCalendarSelector({
  brandConfig,
  isOneWayTrip,
  language,
  onChangeFlightType,
}) {
  const [isVisibleCalendarSelector, seIsVisibleCalendarSelector] =
    React.useState(false);

  const [isVisibleFlexiDates, setIsVisibleFlexiDates] = React.useState(false);

  const propsFlexiDates = useFlexiDates(brandConfig);

  const onClickCalendarSelectorInput = () => seIsVisibleCalendarSelector(true);

  const propsExactDates = useExactDates({
    onCloseDatePicker: () => seIsVisibleCalendarSelector(false),
    onChangeFlightType,
    isOneWayTrip,
    language,
  });

  const onChangeVisibleFlexiDates = (value) => setIsVisibleFlexiDates(value);

  const closeCalendarSelector = () => {
    if (isVisibleFlexiDates) {
      propsExactDates.onRemoveDateRefCurrent();
    }

    seIsVisibleCalendarSelector(false);
  };

  const calendarSelectorText = isVisibleFlexiDates
    ? propsFlexiDates.flexiDatesLabel
    : propsExactDates.dateSelectortextField;

  return {
    onClickCalendarSelectorInput,
    propsExactDates,
    propsFlexiDates,
    isVisibleCalendarSelector,
    onChangeVisibleFlexiDates,
    calendarSelectorText,
    closeCalendarSelector,
    isVisibleFlexiDates,
  };
}
