import { useStore } from '@/store/useStore';

export function useGetThemeConfig() {
  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor, secondaryColor, accentColor } = brandConfig.theme;

  return {
    primaryColor,
    secondaryColor,
    accentColor,
  };
}
