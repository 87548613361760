import * as React from 'react';

type AnyFunction = (...args: unknown[]) => unknown;

export function useEvent<T extends AnyFunction>(callback?: T) {
  const ref = React.useRef<AnyFunction | undefined>(null);

  React.useLayoutEffect(() => {
    ref.current = callback;
  });

  return React.useCallback<AnyFunction>(
    (...args) => ref.current?.(...args),
    []
  ) as T;
}
