import { ProductType } from '@/components/Search/Search.types.ts';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { Flex } from 'antd';
import { Search } from '../Search';
import * as Styled from './Hero.styled';
import { HeroProps } from './Hero.types';

export function Hero({
  backgroundUrl,
  title,
  subtitle,
  promoImage,
}: HeroProps) {
  const { t } = useTranslate();

  const brandConfig = useStore((state) => state.brandConfig);

  const brandProduct = brandConfig.brand.productTypesOrder[0] as ProductType;

  return (
    <Styled.Container
      justify="center"
      align="middle"
      style={{
        backgroundImage: `url(${backgroundUrl})`,
      }}
    >
      <Styled.InnerContainer>
        <Flex vertical gap={8} style={{ marginBottom: 24 }}>
          {promoImage && <Styled.Image src={promoImage} />}
          {title && <Styled.Title>{t(title)}</Styled.Title>}
          {subtitle && <Styled.Subtitle>{t(subtitle)}</Styled.Subtitle>}
        </Flex>
        <Styled.Card data-test="search-form">
          <Search brandProduct={brandProduct} />
        </Styled.Card>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
