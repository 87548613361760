import { CheckIcon, IntroIcon } from '@/components/LmnIcons/LmnIcons';
import { FC } from 'react';
import * as Styled from './SearchInput.styled';

export interface ResultElementProps {
  title: string;
  subtitle: string;
  icon: React.ReactElement;
  imageUrl?: string;
  type: string;
  code: string;
  location: string;
  name: string;
  id: string;
  additionalInfo?: string;
  selected?: boolean;
  preSelected?: boolean;
  disabled?: boolean;
  isSubElement?: boolean;
}

export const ResultElement: FC<ResultElementProps> = ({
  title,
  subtitle,
  icon,
  imageUrl,
  selected,
  preSelected,
  disabled,
  isSubElement,
}) => {
  return (
    <Styled.ResultElement $disabled={disabled} $preSelected={preSelected}>
      {isSubElement && (
        <Styled.SubElement>
          <IntroIcon size={24} />
        </Styled.SubElement>
      )}
      {!!imageUrl ? (
        <Styled.Image src={imageUrl} />
      ) : (
        <Styled.Icon className="icon-container">{icon}</Styled.Icon>
      )}
      <div>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
      </div>
      {selected && (
        <Styled.Check>
          <CheckIcon size={24} />
        </Styled.Check>
      )}
    </Styled.ResultElement>
  );
};
