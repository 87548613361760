import { useDateListContext } from '../Provider';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as Styled from './Date.styled';
import { Dayjs } from 'dayjs';

export function Date({
  month,
  id,
  icon: Icon,
}: {
  month: Dayjs | string;
  id: number;
  icon?: React.ComponentType;
}) {
  const { currentDateIndexs, onSelectDate } = useDateListContext();

  const isSelected = currentDateIndexs.includes(id);

  const disabled =
    currentDateIndexs.length === 3 && !currentDateIndexs.includes(id);

  return (
    <Styled.Container
      role="tab"
      aria-selected={isSelected}
      tabIndex={isSelected ? 0 : -1}
      aria-disabled={disabled}
      disabled={disabled}
      onClick={() => onSelectDate(id)}
    >
      {Icon ? <Icon /> : <CalendarTodayIcon />}
      <Styled.Text>
        {typeof month === 'string' ? month : month.format(`MMM 'YY`)}
      </Styled.Text>
    </Styled.Container>
  );
}
