import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;

  @media (max-width: 756px) {
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;
