export function getSection({
  step,
  bookingType,
}: {
  step: string;
  bookingType: string;
}): string {
  switch (step) {
    case 'hp':
      return 'productHp';
    case 'results':
      return bookingType === 'flight' ? 'flight.results' : 'hdp.results';
    case 'details':
      return bookingType === 'flight' ? 'flight.details' : 'hdp.details';
    case 'payment':
      return bookingType === 'flight' ? 'flight.payment' : 'hdp.payment';
    case 'thankYou':
      return bookingType === 'flight' ? 'flight.thankyou' : 'hdp.thankyou';
    default:
      return '';
  }
}
