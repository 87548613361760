import { Counter } from '@/components/Counter';
import { Rating } from '@/components/HotelCard/Rating';
import { HotelBedIcon, PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import PlaceIcon from '@mui/icons-material/Place';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Deals.styled';
import { FlashSaleOffersBackground } from './FlashSaleOffersBackground';
import { generateDescription } from '../utils/generateDescription';
import { generateUrl } from '../utils/generateUrl';

export const Offers: FC = () => {
  const [brandConfig, deals] = useStore((state) => [
    state.brandConfig,
    state.deals,
  ]);
  const { t } = useTranslate();
  const offers = brandConfig.offers;
  const isFromLM = brandConfig.brand.brandOrigin === 'Lastminute';
  const primaryColor = brandConfig.theme.primaryColor;
  const language = brandConfig.language;
  const currencyFormatter = useCurrencyFormatter();

  const dealList = deals.filter((e) => e.feedType === 'Deal card').slice(0, 3);

  if (isEmpty(dealList)) {
    return null;
  }

  return (
    <Styled.FlashSaleOffersContainer>
      <FlashSaleOffersBackground primaryColor={primaryColor} />
      <Styled.InnerContainer>
        <Styled.TitleContainer>
          <div>
            <Styled.SectionTitle>{t(offers.dealsTitleKey)}</Styled.SectionTitle>
            <Styled.Subtitle>{t(offers.dealsSubtitleKey)}</Styled.Subtitle>
          </div>
          <Styled.AtolImage />
        </Styled.TitleContainer>
        <Styled.OffersCarousel>
          {dealList.map((e, key) => {
            const description = generateDescription(e, t);
            const url = generateUrl(e);
            return (
              <Styled.OfferContainer key={key}>
                <Styled.ImagesCarouselContainer>
                  <Styled.OfferType>
                    <PlaneNEIcon style={{ marginRight: '2px' }} />
                    <HotelBedIcon style={{ marginRight: '8px' }} />
                    {t(`general.product.dp`)}
                  </Styled.OfferType>
                  <Styled.CounterContainer>
                    <Counter date={e.lastMinuteDealExpirationDate} />
                  </Styled.CounterContainer>
                  <Styled.Location>
                    <PlaceIcon sx={{ fontSize: 16, marginRight: '4px' }} />
                    {e.arrivalName}
                  </Styled.Location>
                  <Styled.ImagesCarousel
                    images={e.hotelImages}
                    hideNavigation={false}
                  />
                </Styled.ImagesCarouselContainer>
                <Styled.OfferContent href={url}>
                  <Styled.OfferName>{e.hotelDescription}</Styled.OfferName>
                  <Styled.HotelName>
                    {e.hotelName}{' '}
                    <Rating stars={e.hotelStars as 1} language={language} />
                  </Styled.HotelName>
                  <Styled.Details>{description}</Styled.Details>
                  <Styled.PriceContainer
                    $fromLM={isFromLM}
                    $textColor={primaryColor}
                  >
                    {t('general.price.from')}{' '}
                    <Styled.Price>
                      {currencyFormatter.formatNumber(e.price)}
                    </Styled.Price>{' '}
                    {t('general.price.pp')}
                  </Styled.PriceContainer>
                </Styled.OfferContent>
              </Styled.OfferContainer>
            );
          })}
        </Styled.OffersCarousel>
      </Styled.InnerContainer>
    </Styled.FlashSaleOffersContainer>
  );
};
