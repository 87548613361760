import { StrapiService } from '@/services/codegen-welfare';
import { brandConfigurationPath } from '@/store/utils/getInitialState';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useState } from 'react';

export const InvalidateStrapiCache = () => {
  const [loading, setLoading] = useState(false);

  const handleInvalidateThisStrapiCache = async () => {
    setLoading(true);
    await StrapiService.deleteApiServicesAppStrapiRemoveConfig({
      path: brandConfigurationPath,
    })
      .then(() => {
        console.warn('Strapi Cache Invalidated');
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const handleInvalidateAllStrapiCache = async () => {
    setLoading(true);
    await StrapiService.postApiServicesAppStrapiCleanAllConfigurationCaches()
      .then(() => {
        console.warn('Strapi Cache Invalidated');
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Stack gap={'8px'}>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={() => handleInvalidateThisStrapiCache()}
      >
        Invalidate This Domain Strapi Cache
      </LoadingButton>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={() => handleInvalidateAllStrapiCache()}
      >
        Invalidate ALL Strapi Cache
      </LoadingButton>
    </Stack>
  );
};
