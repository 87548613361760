import { localeObject } from '@/utils/localeObjects';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { ProductType } from '../Search.types';
import { DateRange } from 'react-day-picker';
import { useGetParam } from '@/hooks/useGetParam';
import { FlightTypes } from '@/utils/types/FlightTypes';

export type DateSelectorProps = {
  isOneWayTrip: boolean;
  dateRange: {
    from?: Dayjs;
    to?: Dayjs;
  };
  productType?: ProductType;
  onSelectedDateRange?: ({
    from,
    to,
  }: {
    from: Dayjs;
    to: Dayjs | undefined;
  }) => void;
};

function useGetDefaultDates() {
  const getParam = useGetParam();

  const outboundDate = getParam('outboundDate');

  const returnDate = getParam('returnDate');

  const dateFrom = dayjs().add(3, 'd');

  const dateTo = dayjs(dateFrom).add(3, 'd');

  return {
    from: outboundDate ? dayjs(outboundDate) : dateFrom,
    to: returnDate ? dayjs(returnDate) : dateTo,
  };
}

export function useExactDates({
  onCloseDatePicker,
  isOneWayTrip,
  language,
  onChangeFlightType,
}) {
  const dateRef = React.useRef(null);

  const defaultDates = useGetDefaultDates();

  const [dateRange, setDateRange] = React.useState<{
    from?: Dayjs;
    to?: Dayjs;
  }>(defaultDates);

  const onSelectedDateRange = (date: { from?: Dayjs; to?: Dayjs }) => {
    if (isOneWayTrip) {
      setDateRange({
        from: date.from,
        to: undefined,
      });
    } else {
      setDateRange(date);
    }
  };

  const onSelect = (date: DateRange | Date) => {
    if (date instanceof Date) {
      if (dayjs(date).isSame(dateRange.from)) {
        return;
      }

      onSelectedDateRange({
        from: dayjs(date),
        //NOTE: it is necessary to have a data to display in the other date selectors
        to: dayjs(date).add(1, 'd'),
      });

      return;
    }

    const isClickToDateFrom =
      dayjs(date.from).isSame(dateRange.from) &&
      dayjs(date.to).isSame(dateRange.from);

    const isClickToDateTo =
      dayjs(date.to).isSame(dateRange.to) &&
      dayjs(date.from).isSame(dateRange.to);

    if (isClickToDateFrom) {
      dateRef.current = date.from;

      return;
    }

    if (dayjs(date.from).isBefore(dateRange.from)) {
      onSelectedDateRange({
        from: dayjs(date.from),
        to: dayjs(dateRange.to),
      });

      dateRef.current = date.from;
    } else {
      if (dayjs(date.to).isBefore(dateRange.to)) {
        if (dateRef.current) {
          onSelectedDateRange({
            from: dayjs(dateRef.current),
            to: dayjs(date.to),
          });

          dateRef.current = null;

          onCloseDatePicker?.();
        } else {
          onSelectedDateRange({
            from: dayjs(date.to),
            to: dayjs(dateRange.to),
          });

          dateRef.current = date.to;
        }

        return;
      }

      if (dayjs(date.to).isAfter(dateRange.to) || isClickToDateTo) {
        if (dateRef.current) {
          onSelectedDateRange({
            from: dayjs(dateRef.current),
            to: dayjs(date.to),
          });

          dateRef.current = null;

          onCloseDatePicker?.();
        } else {
          onSelectedDateRange({
            from: dayjs(date.to),
            to: dayjs(date.to).add(1, 'd'),
          });

          dateRef.current = date.to;
        }
        return;
      }
    }
  };

  const onRemoveDateRefCurrent = () => (dateRef.current = null);

  const nightsAmount = dayjs(dateRange.to).diff(dateRange.from, 'days');

  const dateSelectortextField = isOneWayTrip
    ? `${dateRange?.from?.locale(language, localeObject[language]).format('ddd, DD MMM')}`
    : `${dateRange?.from?.locale(language, localeObject[language]).format('ddd, DD MMM')} - ${`${dateRange?.to?.locale(language, localeObject[language]).format('ddd, DD MMM')}`}`;

  const onFlightSelector = (flightType: FlightTypes) => {
    setDateRange({
      from: dateRange.from,
      to:
        flightType === FlightTypes.OW
          ? undefined
          : dateRange.from.add(2, 'days'),
    });

    onChangeFlightType(flightType);
  };

  return {
    dateRange,
    onSelect,
    onRemoveDateRefCurrent,
    nightsAmount,
    dateSelectortextField,
    onFlightSelector,
    onSelectedDateRange,
  };
}
