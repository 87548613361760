import { BookingType } from "@/hooks/useBookingType";

export const getBookingType = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.Flight:
      return 'flight';
    case BookingType.FlightAndHotel:
      return 'dp';
    case BookingType.Hotel:
      return 'hotel';
    default:
      return undefined;
  }
};
