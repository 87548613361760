import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
`;

export const InnerContainer = styled.div`
  display: grid;
  column-gap: 8px;
  width: 100%;
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
  position: relative;
  align-items: center;

  @media (max-width: 756px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 114px);
    overflow-x: unset;
    gap: 8px;
    padding: 0 20px;
    justify-content: center;
  }
`;

export const BlockInitObserved = styled.div`
  @media (max-width: 756px) {
    display: none;
  }
`;

export const BlockEndObserved = styled.div`
  transform: translate(-1px);

  @media (max-width: 756px) {
    display: none;
  }
`;
