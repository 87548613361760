import { useTimeStayListContext } from '../Provider';
import * as Styled from './TimeStay.styled';

export function TimeStay({ text, id, value }) {
  const { onSelectTimeStay, currentTimeStayIndex } = useTimeStayListContext();

  return (
    <Styled.Container
      role="tab"
      aria-selected={currentTimeStayIndex === id}
      tabIndex={currentTimeStayIndex === id ? 0 : -1}
      onClick={() => onSelectTimeStay(value, id, text)}
    >
      {text}
    </Styled.Container>
  );
}
