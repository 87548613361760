import { BrandConfig } from '@/store/utils/configMapper';
import { getLocationIcon } from '../../utils/getLocationIcon';

export const mapDestinationProposals = (brandConfig: BrandConfig) => {
  try {
    return brandConfig.destinationProposals.map((element) => {
      const imageUrl = element?.imageUrl?.data?.attributes?.url;
      return {
        title: element.title,
        subtitle: element.subtitle,
        icon: getLocationIcon(element.type),
        imageUrl: imageUrl,
        type: element.type,
        code: element.code,
        id: element.code,
        location: element.title || element.subtitle,
        name: element.name,
        additionalInfo: element.subtitle,
      };
    });
  } catch (error) {
    return [];
  }
};
