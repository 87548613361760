import styled from '@emotion/styled';
import { Row } from 'antd';

export const Container = styled(Row)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Card = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 10px 20px,
    rgba(0, 0, 0, 0.04) 0px 2px 10px,
    rgba(0, 0, 0, 0.02) 0px 0px 4px;
`;

export const Title = styled.h1`
  color: white;
  text-align: center;
  font-size: 32px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

export const Image = styled.img`
  width: auto;
  height: 120px;

  @media (max-width: 992px) {
    height: 150px;
  }
`;

export const Subtitle = styled.h2`
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

export const InnerContainer = styled.div`
  width: 100%;
  padding: 2em 16px;
  @media (min-width: 576px) {
    max-width: 540px !important;
    padding: 4em 16px;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
    padding: 4em 16px;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
    padding: 6em 16px;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
    padding: 8em 16px;
  }
`;
