import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import * as Styled from './CookiesBanner.styled';
import CookiesBannerModal from './CookiesBannerModal';

import { useStore } from '@/store/useStore';
import { setCookieValue } from '@/store/utils/authenticateExternal/setCookieValue';
import cookiesTypes from '../../../../utils/dictionaries/cookiesTypes.json';

interface CookiesBannerProps {
  visibilityState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const CookiesBanner: FC<CookiesBannerProps> = ({ visibilityState }) => {
  const { t } = useTranslate();
  const brandConfig = useStore((s) => s.brandConfig);
  const { headerColor, primaryColorBgTextColor, accentColor, primaryColor } =
    brandConfig.theme;

  const [showBanner, setShowBanner] = visibilityState;
  const [showManagementModal, setShowManagementModal] =
    useState<boolean>(false);

  const acceptCookies = (acceptedCookies: 'all' | 'required' | string[]) => {
    const allCookies = cookiesTypes.map((i) => i.key);
    const requiredCookies = cookiesTypes
      .map((i) => i.required && i.key)
      .filter((i) => i);
    const newAcceptedCookies = (
      acceptedCookies === 'all'
        ? allCookies
        : acceptedCookies === 'required'
          ? requiredCookies
          : acceptedCookies
    ).join(',');
    setCookieValue(
      'cookies-acceptance',
      newAcceptedCookies,
      new Date(new Date().getTime() + 365 * 86400000)
    );
    setShowBanner(false);
  };

  if (!showBanner) {
    return <></>;
  }

  return (
    <Styled.ConsentWrapper data-test="cookies-consent">
      <Styled.ConsentBanner>
        <Styled.ConsentContainer>
          <Styled.ContentWrapper>
            <Styled.Content>
              <Styled.InfoWrapper>
                <Styled.Title>{t('cookie_consent.title')}</Styled.Title>
                <Styled.Text
                  dangerouslySetInnerHTML={{
                    __html: t('cookie_consent.main_description'),
                  }}
                ></Styled.Text>
              </Styled.InfoWrapper>
              <Styled.ButtonWrapper>
                <Styled.ButtonSecondary
                  onClick={() => setShowManagementModal(true)}
                >
                  {t('cookie_consent.button.manage_cookies')}
                </Styled.ButtonSecondary>
                <Styled.ButtonPrimary
                  onClick={() => acceptCookies('all')}
                  $mainColor={accentColor}
                  $textColor={primaryColorBgTextColor}
                  $size="large"
                  data-test="accept-all-cookies"
                >
                  {t('cookie_consent.button.accept')}
                </Styled.ButtonPrimary>
              </Styled.ButtonWrapper>
            </Styled.Content>
          </Styled.ContentWrapper>
        </Styled.ConsentContainer>
      </Styled.ConsentBanner>
      {showManagementModal && (
        <CookiesBannerModal
          closeModal={() => setShowManagementModal(false)}
          acceptCookies={acceptCookies}
        />
      )}
    </Styled.ConsentWrapper>
  );
};

export default CookiesBanner;
