import { useSearchStore } from '@/components/Search/hooks/useSearchStore';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useGetParam } from '@/hooks/useGetParam';
import { useStore } from '@/store/useStore';
import { europeanCountries } from '@/utils/dictionaries/europeanCountries';
import { FC, useEffect, useState } from 'react';
import { useGetAirports } from '../../hooks/useGetAirports';
import { ResultElementProps } from '../SearchInput/ResultElement';
import { SearchInput } from '../SearchInput/SearchInput';

interface AirportSearchProps {
  label: string;
  placeholder: string;
  error?: string;
  direction: 'OUTBOUND' | 'INBOUND';
  onChange: (e: ResultElementProps | undefined) => void;
  handleOnClickInput: () => void;
}

const AirportSearch: FC<AirportSearchProps> = ({
  label,
  placeholder,
  error,
  direction,
  onChange,
  handleOnClickInput,
}) => {
  const getAirports = useGetAirports();
  const freeFlightCampaign = useFreeFlightCampaign();
  const brandConfig = useStore((state) => state.brandConfig);
  const brandCountry = brandConfig.country;
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState<ResultElementProps>(undefined);
  const [results, setResults] = useState<ResultElementProps[]>(undefined);
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const getParam = useGetParam();
  const departureName = getParam('departureName');
  const departureCode = getParam('departure');
  const destinationCode = getParam('destinationId');
  const destinationName = getParam('destinationName');

  const [departure] = useSearchStore(({ departure }) => [departure]);

  useEffect(() => {
    const fillFromQueryParams = async () => {
      switch (direction) {
        case 'OUTBOUND':
          if (departureName) {
            const ourtboundResults = await getAirports(departureName);
            const selectedOutbound = ourtboundResults.find(
              (e) => e.code === departureCode.toUpperCase()
            );

            setResults(ourtboundResults);
            setSelected(selectedOutbound);
          }
          break;
        case 'INBOUND':
          if (destinationName) {
            const inboundResults = await getAirports(destinationName);
            const selectedInbound = inboundResults.find(
              (e) => e.code === destinationCode.toUpperCase()
            );
            setResults(inboundResults);
            setSelected(selectedInbound);
          }
          break;
        default:
          break;
      }
    };

    fillFromQueryParams();
  }, [departureName, destinationName]);

  useEffect(() => {
    const getData = async (e: string) => {
      const result = await getAirports(e);
      const filterResult = result.filter((e) => {
        if (isFreeFlight) {
          if (direction === 'OUTBOUND') {
            return e.subtitle === freeFlightCampaign.departureCountry;
          } else {
            // SpecialCondition
            if (departure?.code === 'AHO' && e.code === 'BGY') {
              return e;
            }
            const isEUCountry = europeanCountries.find((country) => {
              return country[brandCountry] === e.subtitle;
            });
            return (
              e.subtitle !== freeFlightCampaign.departureCountry && isEUCountry
            );
          }
        } else {
          return e;
        }
      });
      setResults(filterResult);
    };

    const timer = setTimeout(() => {
      if (searchText) getData(searchText);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const handleSelect = (e: ResultElementProps) => {
    setSelected(e);
  };

  const handleClear = () => {
    setResults(undefined);
    setSelected(undefined);
    setSearchText('');
  };

  return (
    <SearchInput
      label={label}
      placeholder={placeholder}
      value={searchText}
      setValue={setSearchText}
      selected={selected}
      setSelected={setSelected}
      results={results}
      onSelect={handleSelect}
      onClear={handleClear}
      onClickInput={handleOnClickInput}
      error={error}
      dataTestKey={
        direction === 'OUTBOUND' ? 'departure-location' : 'destination-location'
      }
    />
  );
};

export { AirportSearch };
