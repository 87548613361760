import styled from '@emotion/styled';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 76px;
  transition: 0.2s;
  user-select: none;
  width: 90px;
  color: #3c3c3c;
  background: rgb(255, 255, 255);
  box-shadow: rgb(234, 234, 235) 0px 0px 0px 1px inset;

  &[aria-selected='true'] {
    background-color: ${(props) => props.theme.colors.primary.main}40;
    box-shadow: none;
    color: ${(props) => props.theme.colors.primary.main};
  }

  &[aria-disabled='true'] {
    opacity: 0.5;

    &:hover {
      box-shadow: rgb(234, 234, 235) 0px 0px 0px 1px inset;
    }
  }

  &:hover {
    box-shadow: ${(props) => props.theme.colors.primary.main} 0px 0px 0px 1px
      inset;
  }

  @media (max-width: 756px) {
    width: 114px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;
