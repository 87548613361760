import { Theme, ThemeProvider } from '@emotion/react';
import { EmotionProviderProps } from './EmotionProvider.types';
import { useGetThemeConfig } from '@/hooks/useGetThemeConfig';

export function EmotionProvider({ children }: EmotionProviderProps) {
  const themeConfig = useGetThemeConfig();

  const theme: Theme = {
    colors: {
      primary: {
        main: themeConfig.primaryColor,
      },
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
