import * as React from 'react';

const mobileValue = 768;
const tabletValue = 1024;
const smallDesktopValue = 1240;

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= mobileValue
  );
  const [isTablet, setIsTablet] = React.useState(
    window.innerWidth <= tabletValue
  );
  const [isSmallDesktop, setSmallDesktop] = React.useState(
    window.innerWidth <= smallDesktopValue
  );

  const handleListener = React.useCallback(() => {
    setIsMobile(window.innerWidth <= mobileValue);
    setIsTablet(window.innerWidth <= tabletValue);
    setSmallDesktop(window.innerWidth <= smallDesktopValue);
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleListener);

    return () => {
      window.removeEventListener('resize', handleListener);
    };
  }, []);

  return { isMobile, isTablet, isSmallDesktop };
}
