import * as React from 'react';
import * as Styled from './Flexibledates.styled';
import { DateList } from './DateList';
import { Date } from './Date';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { TimeStayList } from './TimeStayList';
import { TimeStay } from './TimeStay';
import { useTranslate } from '@tolgee/react';
// import { FlexibleDatesProps } from './Flexibledates.types';

export function Flexibledates(props: any) {
  const { t } = useTranslate();

  const onTimeStay = (value) => {
    props?.onTimeStay(value);
  };

  const onDate = (ids: number[]) => {
    const initIndex = [...ids].shift();

    const endIndex = [...ids].pop();

    const dateInit = props.dates.find((el) => el.id === initIndex);

    const dateEnd = props.dates.find((el) => el.id === endIndex);

    props?.onDate({
      dayInit: dateInit?.days?.init,
      dayEnd: dateEnd?.days?.end,
      monthInit: dateInit?.month,
      monthEnd: dateEnd?.month,
      isAnyDate: ids.includes(0),
      mounthsIds: ids,
    });
  };

  return (
    <Styled.Container>
      <Styled.Block>
        <Styled.TimeStayListTitle>
          {t('flexibleDates.timeStayList.title')}
        </Styled.TimeStayListTitle>
        <TimeStayList
          onTimeStay={onTimeStay}
          timeStayId={props.currentState.flexiDatesData.dateId}
        >
          {props.timeStays.map(({ text, id, value }) => (
            <TimeStay text={text} id={id} key={id} value={value} />
          ))}
        </TimeStayList>
      </Styled.Block>
      <Styled.Block>
        <Styled.DateListTitle>
          {t('flexibleDates.dateList.title')}
        </Styled.DateListTitle>
        <Styled.DateListSubtitle>
          {t('flexibleDates.dateList.subtitle')}
        </Styled.DateListSubtitle>
        <DateList
          onDate={onDate}
          mounthsIds={props.currentState.flexiDatesData.mounthsIds}
        >
          <Date month="Any time" id={0} key={0} icon={AccessTimeFilledIcon} />
          <Styled.Divider>or</Styled.Divider>
          {props.dates.map(({ month, id }) => (
            <Date month={month} id={id} key={id} />
          ))}
        </DateList>
      </Styled.Block>
    </Styled.Container>
  );
}
