import * as React from 'react';
import iconArrowLeft from '@/assets/images/arrow-left.svg';
import iconArrowRight from '@/assets/images/arrow-right.svg';
import { useInView } from '@/hooks/useInView';
import * as Styled from './ScrollToPosition.styled';

enum Position {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const SCROLL_RANGE = 120;

export function ScrollToPosition({ children }) {
  const [isInViewInit, observedInitRef] = useInView();

  const [isInViewEnd, observerdEndRef] = useInView();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const onScrollPosition = (position: string) => {
    if (containerRef.current) {
      if (position === Position.LEFT) {
        containerRef.current.scrollLeft -= SCROLL_RANGE;
      }

      if (position === Position.RIGHT) {
        containerRef.current.scrollLeft += SCROLL_RANGE;
      }
    }
  };

  return (
    <>
      <Styled.ButtonArrowLeft
        isVisible={!isInViewInit}
        onClick={() => onScrollPosition(Position.LEFT)}
      >
        <img src={iconArrowLeft} />
      </Styled.ButtonArrowLeft>
      {children(observedInitRef, observerdEndRef, containerRef)}
      <Styled.ButtonArrowRight
        isVisible={!isInViewEnd}
        onClick={() => onScrollPosition(Position.RIGHT)}
      >
        <img src={iconArrowRight} />
      </Styled.ButtonArrowRight>
    </>
  );
}
