import { StaticData } from '@/services/codegen-romulo';

export const findAirportInfoHDP = (
  staticData: StaticData,
  airportId: string | null | undefined
) => {
  const airport = staticData?.airports?.find((a) => a.id === airportId);
  const country = staticData?.countries?.find(
    (c) => c.id === airport?.countryId
  );
  const continent = staticData?.continents?.find(
    (c) => c.id === country?.continentId
  );
  return { airport, country, continent };
};
