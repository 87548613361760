import * as React from 'react';
import { useEvent } from '@/hooks/useEvent';
import { DateListContext } from '../Provider';
import * as Styled from './DateList.styled';
import { ScrollToPosition } from '../ScrollToPosition';

export function DateList({
  children,
  mounthsIds,
  onDate,
}: {
  children: React.ReactNode;
  mounthsIds?: number[];
  onDate?: (ids: number[]) => void;
}) {
  const [currentDateIndexs, setCurrentDateIndexs] = React.useState(
    mounthsIds ?? [0]
  );

  const onSelectDate = useEvent((index: number) => {
    if (index === 0) {
      setCurrentDateIndexs([index]);
      onDate?.([index]);
      return;
    }

    if (currentDateIndexs.includes(0) && index !== 0) {
      setCurrentDateIndexs([index]);
      onDate?.([index]);
      return;
    }

    if (currentDateIndexs.includes(index) && currentDateIndexs.length === 1) {
      setCurrentDateIndexs([0]);
      onDate?.([0]);
      return;
    }

    if (currentDateIndexs.includes(index)) {
      const newCurrentDate = currentDateIndexs
        .filter((i) => i !== index)
        .sort((a, b) => a - b);

      setCurrentDateIndexs(newCurrentDate);

      onDate?.([...newCurrentDate]);
      return;
    }

    const checkNearIndex = currentDateIndexs.filter(
      (s) => s - index === 1 || s - index === -1
    );

    if (checkNearIndex.length === 0 && currentDateIndexs.length !== 0) {
      setCurrentDateIndexs([index]);
      onDate?.([index]);
      return;
    }

    setCurrentDateIndexs((s) => [...s, index].sort((a, b) => a - b));
    onDate?.([...currentDateIndexs, index].sort((a, b) => a - b));
  });

  const contextValues = React.useMemo(
    () => ({
      currentDateIndexs,
      onSelectDate,
    }),
    [currentDateIndexs, onSelectDate]
  );

  return (
    <DateListContext.Provider value={contextValues}>
      <Styled.Container>
        <ScrollToPosition>
          {(observedInitRef, observerdEndRef, containerRef) => (
            <Styled.InnerContainer role="tablist" ref={containerRef}>
              <Styled.BlockInitObserved ref={observedInitRef} />
              {children}
              <Styled.BlockEndObserved ref={observerdEndRef} />
            </Styled.InnerContainer>
          )}
        </ScrollToPosition>
      </Styled.Container>
    </DateListContext.Provider>
  );
}
