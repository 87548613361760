import * as React from 'react';

export type PropsTimeStayValue = number[] | string[];

export type PropsDateValue = {
  init: string;
  end: string;
};

type PropsDateListContext = {
  currentDateIndexs: number[];
  onSelectDate: (id: number) => void;
};

type PropsTimeStayContext = {
  currentTimeStayIndex: number;
  onSelectTimeStay: (
    value: PropsTimeStayValue,
    index: number,
    text: string
  ) => void;
};

export const DateListContext = React.createContext<PropsDateListContext>(null);

export function useDateListContext() {
  const contextValue = React.useContext(DateListContext);

  if (contextValue == null) {
    throw Error('not context to DateList Parent');
  }

  return contextValue;
}

export const TimeStayListContext =
  React.createContext<PropsTimeStayContext>(null);

export function useTimeStayListContext() {
  const contextValue = React.useContext(TimeStayListContext);

  if (contextValue == null) {
    throw Error('not context to TimeStay Parent');
  }

  return contextValue;
}
