import {
  CityIcon,
  HotelIcon,
  PinIcon,
  PlaneIcon,
} from '@/components/LmnIcons/LmnIcons';
import FlagIcon from '@mui/icons-material/Flag';
export const getLocationIcon = (locationType: string) => {
  switch (locationType) {
    case 'City':
      return <CityIcon size={24} />;
    case 'Hotel':
      return <HotelIcon size={24} />;
    case 'Tag':
      return <PinIcon size={24} />;
    case 'Airport':
      return <PlaneIcon size={24} />;
    case 'Country':
      return <FlagIcon sx={{ width: '24px', height: '24px' }} />;
    default:
      return <CityIcon size={24} />;
  }
};
