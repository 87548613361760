import {
  Airport,
  Continent,
  Country,
  StaticDataTransport,
} from '@/services/codegen-romulo';

export const findAirportInfoTransport = (
  staticData: StaticDataTransport,
  airportId: string | null | undefined
) => {
  const airport: Airport = staticData?.airports?.[airportId];
  const country: Country = staticData?.countries?.[airport?.countryId];
  const continent: Continent = {
    label: staticData?.continents?.[country?.continentId],
    id: country?.continentId,
  };
  return { airport, country, continent };
};
