import * as Styled from './CalendarSelector.styled';
import { ClickAwayListener } from '@mui/material';
import { Flexibledates } from '@/components/Flexibledates';
import { ProductType } from '../Search.types';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import 'react-day-picker/style.css';
import { Modal } from '@/components/Modal';
import { useStore } from '@/store/useStore';
import { DatePicker } from '../components/DateSelector/DatePicker';
import * as Locale from 'date-fns/locale';
import { timeStays } from './utils';
import { localeObject } from '@/utils/localeObjects';

function CalendarInput({ text, onClick }) {
  const { t } = useTranslate();

  return (
    <Styled.InnerContainer>
      <Styled.Input
        label={t('search.when')}
        value={text}
        onClick={onClick}
        fullWidth
        autoComplete="off"
        slotProps={{ inputLabel: { shrink: true } }}
      />
    </Styled.InnerContainer>
  );
}

export function CalendarSelector(props) {
  const brandConfig = useStore((state) => state.brandConfig);

  const { primaryColor, primaryColorBg } = brandConfig.theme;

  const locale = Locale[brandConfig.language];

  const { isMobile } = useIsMobile();

  const { t } = useTranslate();

  return (
    <Styled.Container>
      <CalendarInput
        text={props.calendarSelectorText}
        onClick={props.onClickCalendarSelectorInput}
      />
      {props.isOpenCalendarSelector && !isMobile && (
        <ClickAwayListener
          children={
            <Styled.Calendar>
              <Styled.ButtonsContainer>
                <Styled.Button
                  isSelected={!props.isVisibleFlexiDates}
                  onClick={() => props.onChangeVisibleFlexiDates(false)}
                >
                  Exact dates
                </Styled.Button>
                <Styled.Button
                  isSelected={props.isVisibleFlexiDates}
                  onClick={() => props.onChangeVisibleFlexiDates(true)}
                >
                  Flexible dates
                </Styled.Button>
              </Styled.ButtonsContainer>
              {props.isVisibleFlexiDates ? (
                <Flexibledates
                  onTimeStay={props.propsFlexiDates.onTimeStay}
                  onDate={props.propsFlexiDates.onDate}
                  dates={props.propsFlexiDates.dates}
                  timeStays={timeStays}
                  currentState={props.propsFlexiDates}
                />
              ) : (
                <>
                  <DatePicker
                    isOneWayTrip={props.isOneWayTrip}
                    onSelect={props.propsExactDates.onSelect}
                    dateRange={props.propsExactDates.dateRange}
                    primaryColor={primaryColor}
                    primaryColorBg={primaryColorBg}
                    locale={locale}
                  />
                  {props.productType !== ProductType.F && (
                    <Styled.NightsCounter>
                      {props.propsExactDates.nightsAmount}{' '}
                      {props.propsExactDates.nightsAmount >= 2
                        ? t('general.nights')
                        : t('general.night')}
                    </Styled.NightsCounter>
                  )}
                </>
              )}
            </Styled.Calendar>
          }
          onClickAway={props.onCloseCalendarSelector}
        />
      )}
      {isMobile && (
        <Modal
          title={t('search.dates')}
          isModalOpen={props.isOpenCalendarSelector}
          fullHeight
          onCloseModal={props.onCloseCalendarSelector}
          closable
        >
          <Styled.ButtonsContainer>
            <Styled.Button
              isSelected={!props.isVisibleFlexiDates}
              onClick={() => props.onChangeVisibleFlexiDates(false)}
            >
              Exact dates
            </Styled.Button>
            <Styled.Button
              isSelected={props.isVisibleFlexiDates}
              onClick={() => props.onChangeVisibleFlexiDates(true)}
            >
              Flexible dates
            </Styled.Button>
          </Styled.ButtonsContainer>
          {props.isVisibleFlexiDates ? (
            <Styled.MobileCalendarContainer>
              <Flexibledates
                onTimeStay={props.propsFlexiDates.onTimeStay}
                onDate={props.propsFlexiDates.onDate}
                dates={props.propsFlexiDates.dates}
                timeStays={timeStays}
                currentState={props.propsFlexiDates}
              />
            </Styled.MobileCalendarContainer>
          ) : (
            <Styled.MobileCalendarContainer>
              <Styled.MobileDatesContainer>
                {!props.isOneWayTrip && (
                  <Styled.Item $selected={false} $color={primaryColor}>
                    {props.propsExactDates.dateRange?.from
                      ?.locale(
                        brandConfig.language,
                        localeObject[brandConfig.language]
                      )
                      .format('ddd, DD MMM')}
                  </Styled.Item>
                )}
                <Styled.DatesDivider>-</Styled.DatesDivider>
                <Styled.Item $selected={false} $color={primaryColor}>
                  {props.propsExactDates.dateRange?.to
                    ?.locale(
                      brandConfig.language,
                      localeObject[brandConfig.language]
                    )
                    .format('ddd, DD MMM')}
                </Styled.Item>
              </Styled.MobileDatesContainer>
              <Styled.DateSelectorContainer>
                <DatePicker
                  isOneWayTrip={props.isOneWayTrip}
                  onSelect={props.propsExactDates.onSelect}
                  dateRange={props.propsExactDates.dateRange}
                  primaryColor={primaryColor}
                  primaryColorBg={primaryColorBg}
                  locale={locale}
                />
              </Styled.DateSelectorContainer>
            </Styled.MobileCalendarContainer>
          )}
        </Modal>
      )}
    </Styled.Container>
  );
}
