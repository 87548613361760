import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  z-index: 9;
  overflow: hidden;

  @media (max-width: 756px) {
    width: 100%;
    position: relative;
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    box-shadow: none;
  }
`;

export const Block = styled.div`
  @media (max-width: 756px) {
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 4px;
    width: 100%;
    padding: 12px 0;
    margin-bottom: 8px;
  }
`;

export const Divider = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #3c3c3c;

  @media (max-width: 756px) {
    display: none;
  }
`;

export const DateListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  user-select: none;
  margin-top: 32px;

  @media (max-width: 756px) {
    padding: 0 20px;
    justify-content: flex-start;
    margin-top: 0;
  }
`;

export const TimeStayListTitle = styled(DateListTitle)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const DateListSubtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #74747c;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  margin-bottom: 20px;

  @media (max-width: 756px) {
    padding: 0 20px;
    justify-content: flex-start;
  }
`;
