import styled from '@emotion/styled';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  white-space: nowrap;
  user-select: none;
  border-radius: 22px;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 16px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(234, 234, 235) 0px 0px 0px 1px inset;
  height: 44px;

  &[aria-selected='true'] {
    background-color: ${(props) => props.theme.colors.primary.main}40;
    box-shadow: none;
    color: ${(props) => props.theme.colors.primary.main};
  }

  &:hover {
    box-shadow: ${(props) => props.theme.colors.primary.main} 0px 0px 0px 1px
      inset;
  }
`;
